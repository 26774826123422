import React from "react";
export function Code() {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          fill="#98EED5"
          d="M16.7929 6.79289C17.1834 6.40237 17.8166 6.40237 18.2071 6.79289L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L18.2071 17.2071C17.8166 17.5976 17.1834 17.5976 16.7929 17.2071C16.4024 16.8166 16.4024 16.1834 16.7929 15.7929L20.5858 12L16.7929 8.20711C16.4024 7.81658 16.4024 7.18342 16.7929 6.79289Z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
        <path
          fill="#98EED5"
          d="M7.20711 6.79289C7.59763 7.18342 7.59763 7.81658 7.20711 8.20711L3.41421 12L7.20711 15.7929C7.59763 16.1834 7.59763 16.8166 7.20711 17.2071C6.81658 17.5976 6.18342 17.5976 5.79289 17.2071L1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929L5.79289 6.79289C6.18342 6.40237 6.81658 6.40237 7.20711 6.79289Z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
        <path
          fill="#85D0CC"
          d="M15.4282 3.06638C15.9439 3.26425 16.2014 3.84265 16.0036 4.35827L9.86357 20.3583C9.6657 20.8739 9.0873 21.1315 8.57168 20.9336C8.05606 20.7357 7.79847 20.1573 7.99634 19.6417L14.1363 3.64172C14.3342 3.1261 14.9126 2.86851 15.4282 3.06638Z"
          clipRule="evenodd"
          fillRule="evenodd"
        ></path>
      </svg>
    </>
  );
}
