import React, { MouseEvent } from "react";
import styled from "styled-components";
import { TopicBubble } from "./TopicBubble/TopicBubble";
import { Mountains, Code, ElectricGuitar, Family } from "../../icons";
import { scrollToTop, below } from "../../utilities";
import { Link } from "gatsby";
import Jogging from "../../../assets/Jogging.inline.svg";
import Coding from "../../../assets/Coding.inline.svg";
import PersonWithHeadphones from "../../../assets/PersonWithHeadphones.inline.svg";
import Person from "../../../assets/Person.inline.svg";

const handleClick = (e: MouseEvent<HTMLDivElement>) => {
  if (e.currentTarget.dataset.target) {
    const target = e.currentTarget.dataset.target;
    scrollToTop(target);
  }
};

const SpanLink = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.link.default};
`;

export const TopicBubbleContent = () => {
  return (
    <>
      <TopicBubble
        click={handleClick}
        icon={<ElectricGuitar />}
        name={"Guitar"}
        activityType={"guitar"}
      />
      <TopicBubble
        click={handleClick}
        icon={<Mountains />}
        name={"Outdoors"}
        activityType={"running"}
      />
      <TopicBubble
        click={handleClick}
        icon={<Code />}
        name={"Coding"}
        activityType={"coding"}
      />
    </>
  );
};

const TopicContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

interface TopicContentItemWrapperProps {
  direction: "forward" | "reverse";
}

const TopicContentItemWrapper = styled.article<TopicContentItemWrapperProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => {
    return props.direction === "forward" ? "row" : "row-reverse";
  }};
  ${below.medium`
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
  `};

  margin-bottom: 24px;

  width: 100%;
  :last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-right: 16px;
  }
`;

const TopicContentBubbleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;
  justify-content: center;
  svg {
    width: 65%;
  }

  :first-child {
    svg {
      width: 50%;
    }
  }
`;

const TopicContentBubbleTitle = styled.h2`
  margin: 0 16px;
  align-self: center;
`;

const TopicContentBubbleContent = styled.div`
  max-width: 60%;
  p {
    margin-bottom: 1.1rem;
  }

  ${below.medium`
    max-width: 100%;
  `};
`;

const WelcomeSection = () => {
  return (
    <TopicContentItemWrapper direction="forward">
      <TopicContentBubbleTitleWrapper>
        <Person />
      </TopicContentBubbleTitleWrapper>
      <TopicContentBubbleContent>
        <p>
          Hello and welcome to my personal web page. This is where I'll share
          some of the things that I'm most interested in, and it also gives me a
          playground to try out new technologies.
        </p>
        <p>
          I'll also be able to improve my design skills (which aren't great!)
          and improve my writing. So I'll be updating the site frequently and
          the entire design will probably change all the time. Below, you'll
          find some soundbite sized highlights of some of the things that I
          enjoy, and I'll be adding more content soon.
        </p>
        <p>
          You can also check out the <Link to={"/blog"}>blog</Link>, where I
          write about anything that's on my mind. Most of the posts will be
          about music, tech, running, and management. I might also talk about
          some of the other things going on in my life.
        </p>
      </TopicContentBubbleContent>
    </TopicContentItemWrapper>
  );
};

const GuitarSection = () => {
  return (
    <TopicContentItemWrapper id={"guitar"} direction={"reverse"}>
      <TopicContentBubbleTitleWrapper>
        <TopicContentBubbleTitle>Guitar and Music</TopicContentBubbleTitle>
        <PersonWithHeadphones />
      </TopicContentBubbleTitleWrapper>
      <TopicContentBubbleContent>
        <p>
          Some people like all sorts of music. Not me. I don't think there's any
          question that the best era for music was the late 1980s through the
          mid 1990s. Guns 'n Roses, Nirvana, Soundgarden, the best Metallica
          albums. It didn't get better than that.
        </p>
        <p>
          I'm only half-serious. My favorite music really is from those years,
          but I love rock and metal music from all different eras. Some of my
          favorite bands are The Beatles, Pink Floyd, Tool, Iron Maiden, Black
          Sabbath, Led Zepplin, and Breaking Benjamin, and I enjoy a lot of
          newer progressive music like Plini and Animals as Leaders.
        </p>
        <p>
          I love the blues, especially Stevie Ray Vaughn, Kenny Wayne Shepherd,
          and Joe Bonamassa. The last concert I saw, just a week or two before
          the Covid-19 lockdowns, was The Robert Cray Band. I miss concerts.
        </p>
        <p>
          And when I'm working or running, I listen to a lot of Trance (it makes
          great background music).
        </p>
        <p>
          My main passion outside of technology is the guitar. You can expect to
          hear a lot more about that on this page and some of the other side
          projects that I'm working on.
        </p>
        <SpanLink onClick={() => scrollToTop()}>Back to top...</SpanLink>
      </TopicContentBubbleContent>
    </TopicContentItemWrapper>
  );
};

const OutdoorsSection = () => {
  return (
    <TopicContentItemWrapper id={"running"} direction={"forward"}>
      <TopicContentBubbleTitleWrapper>
        <TopicContentBubbleTitle>
          Running and the Outdoors
        </TopicContentBubbleTitle>
        <Jogging />
      </TopicContentBubbleTitleWrapper>

      <TopicContentBubbleContent>
        <p>
          I spend far too much time in front of the computer screen. Whether
          it's for work (I'm a software engineering manager), or for fun (I
          always have a bunch of side projects that I'm working on), or for
          education (you need to be a lifelong learner to survive in tech), I'm
          usually staring at a screen for 12-15 hours a day. That's not an
          exaggeration, ScreenTime will verify it.
        </p>
        <p>
          To balance that out, I spend as much time as possible running and just
          being outside. In 2020 I racked up over 1200 miles of running. Most of
          that was on the roads or the local trails, and the rest was on my
          NordicTrack treadmill with iFit. If you've always hated treadmill
          running (like me), you ought to check out iFit. It's awesome. In 2021
          I set a goal of 1250 miles, and I'm on track to blow past that.
        </p>
        <p>
          What I really love is trail running, and once the pandemic is over
          there are a bunch of trail races that I want to do. My ultimate goal
          is to start competing in ultra-marathons. I'm still pretty far away
          from that, but I don't think a 50k is too far out of reach.
        </p>
        <p>
          When I'm not running on the trails, I love to get out and hike with my
          wife. We're lucky enough to live in western Connecticut, just a few
          miles from the Appalacian Trail, and within an easy drive to the
          Catskill and Adirondack Mountains in eastern New York. You can find us
          on the trails early in the morning on most weekends, as long as there
          isn't snow on the ground.
        </p>
        <SpanLink onClick={() => scrollToTop()}>Back to top...</SpanLink>
      </TopicContentBubbleContent>
    </TopicContentItemWrapper>
  );
};

const CodingSection = () => {
  return (
    <TopicContentItemWrapper id={"coding"} direction={"reverse"}>
      <TopicContentBubbleTitleWrapper>
        <TopicContentBubbleTitle>Coding</TopicContentBubbleTitle>
        <Coding />
      </TopicContentBubbleTitleWrapper>
      <TopicContentBubbleContent>
        <p>
          I discovered coding later than a lot of people in the Software
          Engineering field. I was an Electronics Technician in the US Navy from
          the late 1990s through the mid 'oughts, and in college I studied
          Electrical and Computer Engineering. It wasn't until my second year in
          college, when I took my first programming class, that I really caught
          the bug. I didn't change majors, but I did start spending a lot of my
          free time (whatever that meant as a EE/CompE double major) working on
          programming projects.
        </p>
        <p>
          This was during the first years of the AppStore (iPhone OS 3 and 4),
          when the most popular apps simulated drinking a beer or making "funny"
          noises. Back then it was hard to believe that there were 10,000 apps
          on the store, and it was still relatively common for apps built by one
          or two people to hit big and make a lot of money.
        </p>
        <p>
          Maybe it never was that common, but me and a few of my friends got
          together and formed an LLC with dreams of striking gold on the
          AppStore. We taught ourselves Objective-C (back in the pre-ARC days)
          and CocoaTouch and released a simple game. Unfortunately, we didn't
          know anything about business and the app (and the LLC) never went
          anywhere, but it did set a pattern for me that carried on throughout
          school. During the summers, when most people were doing internships I
          was working on coding projects. I'll be adding a projects page to the
          site eventually where I talk more about those, but for now it's enough
          to say that the takeaway was that I completely lost interest in
          Electrical Engineering.
        </p>
        <p>
          In fact, I didn't even apply for any EE jobs. I had decided that I was
          going to write software for a living, and I was offered a job at the
          first company that I interviewed at. I'm still at Bentley Systems, 10
          years later. I guess this is as good a place as any to say that
          everything on this site is my own content and does not in any way
          represent Bentley Systems. Disclaimer over.
        </p>
        <p>
          During my career, I've worked on desktop applications using .Net. I've
          worked on cross-platform mobile applications built on top of a
          JavaScript framework that Bentley developed in-house. I moved into a
          tech lead role on a different mobile application built in TypeScript,
          then I transitioned into a technical managment role on a team building
          a new web application using React and Azure cloud services. Today I
          manage several teams of awesome people who's work spans applications
          and infrastructure.
        </p>
        <p>
          Although most of my work time these days is spent managing people and
          projects, I still make technical contributions to most of my teams.
          And I work on side projects so that I can stay up to date on emerging
          technologies. Or if not emerging, then at least technologies that we
          don't use in our day-to-day work yet. This is something I plan to
          write a lot about, as I think it's critical for engineering managers
          to be able to add technical value even as they become less involved in
          the actual code.
        </p>
        <p>
          I read somewhere that management isn't a promotion, it's a career
          change, and that has certainly been my experience. I've had the good
          fortune to be a part of many great teams and I'm very appreciative of
          that.
        </p>
        <SpanLink onClick={() => scrollToTop()}>Back to top...</SpanLink>
      </TopicContentBubbleContent>
    </TopicContentItemWrapper>
  );
};

export const TopicContent = () => {
  return (
    <TopicContentWrapper>
      <WelcomeSection />
      <GuitarSection />
      <OutdoorsSection />
      <CodingSection />
    </TopicContentWrapper>
  );
};
