import React from "react";
export function ElectricGuitar() {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke="#265F58"
          fill="#98EED5"
          d="M12 14L16.0053 12.5L14.5033 15.5C14.5033 15.5 17.0301 18.5414 16.0053 20.3032C14.6892 22.5657 9.31596 22.5657 7.99989 20.3032C6.97507 18.5414 9.49661 15.5 9.49661 15.5L7.99989 12.5L12 14Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke="#265F58"
          d="M12 14V2"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke="#265F58"
          d="M12 18H12.01"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#265F58"
          d="M13 2H11L12 4L13 2Z"
        ></path>
      </svg>
    </>
  );
}
