import styled from "styled-components";
import { below } from "../../utilities";
import NameBackgroundImage from "../../../assets/HomePageNameBackgroundBlob.inline.svg";

export const HeroSection = styled.section`
  height: 273px;
`;

export const HeroBackgroundImage = styled(NameBackgroundImage)`
  width: 722px;
  height: 273px;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 0;
  ${below.medium`
    width: 100%;
    top: 66px;
  `};
`;

export const HeroInformationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 50px;
  z-index: 100;

  .pageTitle {
    align-self: flex-start;
    z-index: 100;
    margin-top: 2px;
  }

  ${below.medium`
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    text-align: center;
    .pageTitle {
      align-self: center;
      padding-bottom: 8px;
      margin-top: 0;
    }
  `};
`;
