import React from "react";
import styled from "styled-components";
import { Layout } from "../../layouts/Layout";
import {
  TopicBubbleContent,
  TopicContent,
} from "../../components/Topics/Topics";
import {
  HeroBackgroundImage,
  HeroInformationRow,
  HeroSection,
} from "../../components/Hero";
import { below } from "../../utilities";

const HomePageWrapper = styled.div`
  min-height: 100vh;
`;

const TopicBubbleSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 400px;
  z-index: 100;
  div {
    margin: 2px 10px;
  }

  ${below.medium`
    width: 100%;
    max-width: 100%;
  `};
`;

const Home = () => {
  return (
    <Layout>
      <HomePageWrapper>
        <HeroSection>
          <HeroBackgroundImage />
          <HeroInformationRow>
            <div className={"pageTitle"}>
              <h1>Carl Hinkle</h1>
              <h3>Projects, hobbies, and random thoughts</h3>
            </div>
            <TopicBubbleSection>
              <TopicBubbleContent />
            </TopicBubbleSection>
          </HeroInformationRow>
        </HeroSection>
        <TopicContent />
      </HomePageWrapper>
    </Layout>
  );
};

export default Home;
