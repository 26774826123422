import React from "react";

export function Mountains() {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#265F58"
          fill="#85D0CC"
          d="M9.94 9.78006C9.05 8.62006 7.22 8.78006 6.55 10.0801L2 19.0001H8L11.39 11.6801L9.94 9.78006Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#265F58"
          fill="#98EED5"
          d="M22 19L17.62 6.38002C17.02 4.64002 14.71 4.52002 13.94 6.18002L8 19H22Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#265F58"
          d="M4.98999 13.13L6.85999 14.46L10.59 13.4H10.6"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#265F58"
          d="M11.88 10.63L14.77 9.62L16.98 11.54L18.99 10.34"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#265F58"
          d="M9.20996 16.38L12.34 15.15L13.87 16.43L15.8 15.67L18.83 19"
        ></path>
      </svg>
    </>
  );
}
