import React, { MouseEvent, ReactElement } from "react";
import { ActivityTypes } from "../../../utilities/types/ActivityTypes";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export interface TopicBubbleProps {
  icon: ReactElement;
  name: string;
  activityType: ActivityTypes;
  click: (e: MouseEvent<HTMLDivElement>) => void;
}

interface BubbleContainerProps {
  activityType: ActivityTypes;
}

const BubbleContainer = styled.div<BubbleContainerProps>`
  transition: all 0.2s;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  box-shadow: 0 2px 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-color: ${(props) => {
    switch (props.activityType) {
      case "guitar":
        return props.theme.colors.activities.guitar;
      case "running":
        return props.theme.colors.activities.running;
      case "family":
        return props.theme.colors.activities.family;
      case "coding":
        return props.theme.colors.activities.coding;
      case "hiking":
        return props.theme.colors.activities.hiking;
    }
  }};

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    box-shadow: 1px 3px 15px 10px rgba(0, 0, 0, 0.3);
  }
`;

export const TopicBubble = (
  props: TopicBubbleProps & React.ComponentProps<"span">
) => {
  return (
    <BubbleContainer
      title={props.name}
      onClick={props.click}
      activityType={props.activityType}
      data-tip={props.name}
      data-target={props.activityType}
    >
      {props.icon}
      <ReactTooltip
        delayShow={500}
        effect={"solid"}
        backgroundColor={"rgba(0,0,0,0.2)"}
        textColor={"#C3CBD1"}
      />
    </BubbleContainer>
  );
};
